import React, { useEffect, useState, lazy, Suspense } from 'react';
import ScrollToTopButton from '../ScrollToTopButton/ScrollToTopButton';

const ArticleContent = ({ article }) => {
  const [ArticleComponent, setArticleComponent] = useState(null);

  useEffect(() => {
    const importComponent = async () => {
      try {
        const module = await import(`./articles/${article.componentPath}`);
        // Ensure the module has a default export
        if (module && module.default) {
          setArticleComponent(() => module.default);
        } else {
          console.error('Invalid component module:', module);
        }
      } catch (error) {
        console.error('Error loading component:', error);
      }
    };
    

    importComponent();
  }, [article.componentPath]);

  return (
    <div className='article-content-body'>
      {ArticleComponent ? (
        <Suspense fallback={<div>Loading...</div>}>
          <ArticleComponent />
        </Suspense>
      ) : (
        <div>Loading...</div>
        )}
        <ScrollToTopButton></ScrollToTopButton>
    </div>
  );
};

export default ArticleContent;
