
import api from './api/axiosConfig';
import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { articles } from './data';
import ArticleList from './components/ArticleList/ArticleList';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';




function App() {
  return (
    <div className="App">

    <BrowserRouter basename='/articles/'>
    <Routes>
      <Route path="/" element={<ArticleList articles={articles}></ArticleList>} />
      <Route path="/:id" element={<ArticleList articles={articles}></ArticleList>} />
    </Routes>
  </BrowserRouter>

    </div>
  );
}

export default App;
