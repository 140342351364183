import React from 'react';
import './footer.css';
const Footer = () => {
  return (
    <footer>
      <span>&copy; {new Date().getFullYear()} SPARKLING-ARTICLE <span className='t-ft'>by Andrii Skyba</span></span>
    </footer>
  );
};

export default Footer;
