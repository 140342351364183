import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css'; // Import your styles here

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the button when user scrolls down
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll to the top when the button is clicked
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      id="scrollButton"
      className={`scroll-button ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    ><span>↑</span></button>
  );
};

export default ScrollToTopButton;
