// src/data.js
export const articles = [
    {
      m: 0,
      id: 'by Egor Levchenko',
      title: 'Финансовая грамотность',
      componentPath: 'FinancicalDiscipline',
      previewImagePath: 'financical_discipline/s1.png'
    },
    {
      m: 1,
      id: 'by Egor Levchenko',
      title: 'SEC / Крипта и фиаско регуляторов',
      componentPath: 'CryptoSECInsights',
      previewImagePath: 'cryptoSECInsights/s1.png'
    },
    {
      m: 2,
      id: 'by Egor Levchenko',
      title: 'Примени EQUILIBRIUM',
      componentPath: 'EquilibriumFor',
      previewImagePath: 'equilibriumFor/s1.png'
    },
    {
      m: 3,
      id: 'by Egor Levchenko',
      title: 'Ключевые факторы уверенности в себе',
      componentPath: 'SelfConfidenceFactors',
      previewImagePath: 'selfConfidenceFactors/s1.png'
    }
    ,
    {
      m: 4,
      id: 'by Egor Levchenko',
      title: 'Где взять окружение за счет которого ты будешь прогрессировать',
      componentPath: 'ProgressEnvironmentSource',
      previewImagePath: 'progressEnvironmentSource/s1.png'
    }    ,
    {
      m: 5,
      id: 'by Egor Levchenko',
      title: 'Мотивация и самодисциплина',
      componentPath: 'HowToFindMotivation',
      previewImagePath: 'howToFindMotivation/s1.png'
    },
    {
      m: 9845,
      id: 'Andrii Skyba',
      title: '',
      componentPath: 'BattleRoyaleProject',
      previewImagePath: 'howToFindMotivation/s1.png'
    }
    ,
    {
      m: 9846,
      id: 'Andrii Skyba',
      title: '',
      componentPath: 'SquareBattle',
      previewImagePath: 'howToFindMotivation/s1.png'
    }
  ];
  