import React from 'react';
import './header.css';
import MyLogo from '../../logo.png';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
const Header = () => {
  return (
    <header>
        <div className='header-first-item'>
            <img src={MyLogo} alt="My Logo" width="50" height="50" />
            <div className='title-texts-body'>
                <span className='title-top-text'>sparkling</span>
                <h1 className='title-main-text'>Article</h1>
            </div>
        </div>
        <div className='header-second-item'>
        </div>
    </header>
  );
};

export default Header;
//<BurgerMenu></BurgerMenu>
