import React, { useState, useEffect } from 'react';
import {useNavigate, useSearchParams } from 'react-router-dom';
import './articleList.css';
import Article from './Article';
import ArticleContent from './ArticleContent';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const ArticleList = ({ articles }) => {
  const [article, setArticle] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  function openArticle(id) {
    if(id == -1)
      setArticle(null);

    navigate("/" + (id == -1 ? "" : "?id=" + id));
  }

  function getArticle(idd)
  {
    return articles.find(a => a.m == idd);
  }

  useEffect(() => {
    const idFromParams = searchParams.get("id");

    if (idFromParams) {
      const articleById = getArticle(idFromParams);
      
      if (articleById) {
        setArticle(articleById);
      }
    }
  }, [searchParams, articles, article]);



  return (
    <div>
      {
 ((article != null && article.m < 9845) || (article == null)) &&
 <Header />
}
    <div className='articles-list'>
      {(article === null) && (
        
        <div className='articles-container'>
          {articles.map((article, index) => (
            (article.title != '') &&
            <div className='article-body' onClick={() => openArticle(article.m)} key={index}>
              <Article article={article}></Article>
            </div>
          ))}
        </div>
      )}
      {(article !== null) && (
        <div className='main-article-content'>
          {
 (  article.m < 9845) &&
 <button className="back-btn" onClick={() => openArticle(-1)}>➤</button>
}
          <div className='article-content'>
            <ArticleContent article={article}></ArticleContent>
          </div>
        </div>
      )}
    </div>
    {
 ((article != null && article.m < 9845) || (article == null)) &&
    <Footer />
}
    </div>
  );
};

export default ArticleList;
