import React from 'react';
import { CloseButton } from 'react-bootstrap';

const Article = ({ article }) => {
  return (
    <div className='article-item'>
      <span className='id-txt'>{article.id}</span>
      <h3 className='text-article'>{article.title}</h3>
      <img className="figure_wrapper" src={require('../../assets/posts/'+article.previewImagePath)}/>
    </div>
  );
};

export default Article;
